<template>


 <b-row :key="componentKeyListTransactions">

<b-col  cols="12" sm="12" md="12" xl="3" lg="3">

    <b-card>

        <b-row>

            <b-col  cols="12" sm="12" md="12" xl="12" lg="12">

                  

                        <h4 class="font-weight-bolder" style="text-align: left !important;">
                          <feather-icon
                        icon="FilterIcon"
                        size="21"
                        class="color_icon"
                        />
                        Filtros de búsquedas
                        <hr>
                        </h4>
                       
             

            </b-col>

            <b-col  cols="12" sm="12" md="6" xl="6" lg="12" style="margin-bottom: 10px;">

                     <b-form-select
                        v-model="type"
                        :disabled="isDisabled"
                        required
                        :options="optionType"
                        />

             

            </b-col>

                  <b-col  cols="12" sm="12" md="6" xl="6" lg="12" >

                     <b-form-select
                        v-model="status"
                        :disabled="isDisabled"
                        required
                        :options="optionStatus"
                        />

          

            </b-col>

           <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="margin-top: 15px;">

            
                 
                                                <b-button
                                                  type="submit"
                                                  variant="primary"
                                                  block
                                                  :disabled="isDisabled"
                                                  @click="search"
                                                >

                                                      <feather-icon
                                                        icon="SearchIcon"
                                                        size="15"
                                                        style="color:#fff"
                                                        /> Buscar transacciones

                                                </b-button>


            </b-col>


        </b-row>


    </b-card>
</b-col>


<b-col  cols="12" sm="12" md="12" xl="9" lg="9">
         <b-card>



              <listado-transacciones
            :key="componentKeyListadoTransacciones"
            :user-id="userId"
             :token-auth="tokenAuth"
             :type="type"
             :status="status"
          />


        </b-card>
</b-col>




</b-row>

</template>

<script>
import {
  BButton, BRow, BCol, BCard,BFormSelect
} from 'bootstrap-vue'
import ListadoTransacciones from './ListadoTransacciones.vue'

export default {
  components: {
    BButton,
    BFormSelect,
    BRow,
    BCol,
    ListadoTransacciones,
    BCard
  },
  data() {

  const datos_json = JSON.parse(this.$userGlobal)


    return {
      tokenAuth: this.$tokenGlobal,
       userId:datos_json.userId,
       isDisabled:true,
       componentKeyListadoTransacciones:0,
       componentKeyListTransactions:0,
        optionStatus: [
        { value: 'Todos', text: 'Todos'},
        { value: 'Disponible', text: 'Disponible' },
        { value: 'Pendiente de enviar recibo firmado', text: 'Pendiente de enviar recibo firmado' },
        { value: 'Enviada para revision', text: 'Enviada para revision' },
        { value: 'Pendiente', text: 'Pendiente' },
        { value: 'Rechazado', text: 'Rechazado' },
         { value: 'Fallido', text: 'Fallido' },

      ], optionType: [
        { value: 'Todos', text: 'Todos'},
        { value: 'Retiro de dinero', text: 'Retiro de dinero' },
        { value: "Link de Billetera - Tarjeta", text: "Link de Billetera - Tarjeta" },
        { value: "Link de Billetera - Saldo EQCoop", text: "Link de Billetera - Saldo EQCoop" },
        { value: "Link Masivo - Saldo EQCoop", text: "Link Masivo - Saldo EQCoop" },
        { value: "Pago por QR de negocio", text: "Pago por QR de negocio" },
        { value: 'Transferencia interbilletera', text: 'Transferencia interbilletera' },
        { value: 'Recarga por depósito o transferencia bancaria', text: 'Recarga por depósito o transferencia' },
        { value: 'Recarga por Wester Union/Red Activa', text: 'Recarga por Wester Union/Red Activa' },
        { value: 'Recarga por Paypal', text: 'Recarga por Paypal' },
        { value: 'Recarga por tarjeta', text: 'Recarga por tarjeta' },
        { value: "Conversion de criptomoneda", text: "Conversion de criptomoneda" },
        { value: "Compra de criptomoneda", text: "Compra de criptomoneda" },
        { value: "Remesas", text: "Envios de dinero (Remesas)" },
        { value: "Fondo de Ahorro", text: "Fondo de Ahorro" },
        { value: "Comision Fondo de Ahorro", text: "Comision Fondo de Ahorro" },
        { value: "Comision Diaria Fondo de Ahorro", text: "Comision Diaria Fondo de Ahorro" },
        { value: "Retorno del Fondo de Ahorro", text: "Retorno del Fondo de Ahorro" },
        { value: "Aumento en Fondo de Ahorro", text: "Aumento en Fondo de Ahorro" },
        { value: "Suscripcion al plan Prevent", text: "Suscripcion al plan Prevent" },
        { value: "Suscripcion al plan Pets", text: "Suscripcion al plan Pets" },
      ],
       type:"Todos",
       status:"Todos",

    }


  },
  computed: {

  },
  watch: {

  },

  mounted() {


    document.title = 'EQCoop - Transacciones'

        this.$eventBus.$on('reiniciarListadoTransacciones', () => {
      this.componentKeyListadoTransacciones += 1
      this.isDisabled=true;
    });

    this.$eventBus.$on('isDisabledFalseTransacciones', () => {

      this.isDisabled=false;
    });


    this.$eventBus.$on('reiniciarListTransacciones', () => {
      this.componentKeyListTransactions += 1
    })

      

  },
  methods: {

    search(){
      this.$eventBus.$emit('reiniciarListadoTransacciones')
   

    }



  },
}
</script>

<style lang="scss">


</style>