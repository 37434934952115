<template>

    <b-modal
          ref="modalComprobante"
          centered
          hide-footer
          size="sm"
          title="Envio de comprobante firmado"
          @hidden="closeModalComprobante"
        >
          <div class="d-block text-center">
    
            <b-form
              autocomplete="off"
              @submit.prevent="updateComprobante"
            >
    
              <b-row>
                <b-col
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  style="margin-bottom: 10px;"
                >
                
              <p style="margin:0px; text-align: left;">Tamaño máximo del archivo 2mb (*);</p>
                    
                    </b-col>
                <b-col
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  style="margin-bottom: 10px;"
                >
    
                
                  <b-form-file
                    id="filePdf"
                    v-model="filePdf"
                    required
                    :disabled="isActivePdf"
                    accept="application/pdf"
                    placeholder="Seleccione un archivo pdf o suéltelo aquí..."
                    drop-placeholder="Suelte el archivo pdf aqui..."
                    @change="previewImage1"
                  />
    
                  <p style="margin: 0;font-size: 12px;display:flex">{{ uploadValue1.toFixed()+"%" }}
                    <progress
                      style="width:100%"
                      :value="uploadValue1"
                      max="100"
                    />  </p>
    
                </b-col>

                <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
                style="margin-bottom: 10px;"
                >

                <b-alert
                  variant="primary"
                  show
                  
                  >
                  <div class="alert-body">
                          
                    <p style="font-size:12px;text-align: center;">¿Su PDF pesa más de 2mb? Intente utilizar el siguiente <a class="a_link" href="https://www.ilovepdf.com/es/comprimir_pdf" target="_blank">compresor pdf online</a></p>


                  </div>
                  </b-alert>
                  
                  </b-col>
    
                <b-col
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                >
    
                  <p
                    class="textLabel"
                    style="text-align: center;margin-bottom: 10px;"
                  >
                    Ingrese su código PIN (*): <feather-icon
                                      icon="HelpCircleIcon"
                                      size="16"
                                        v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                      title="Código PIN de seguridad"
                                      style="margin-right: 5px;cursor:pointer;"
                                      /> </p>
    
                  <PincodeInput
                    v-model="pinPdf"
                    placeholder="-"
                    :length="6"
                    :autofocus="false"
                    :secure="true"
                    :disabled="isActivePdf"
                    required
                  />
    
                </b-col>
    
                <b-col
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                >
    
                  <b-button
                    id="btn_pdf"
                    ref="btn_pdf"
                    type="submit"
                    variant="primary"
                    :disabled="isActivePdf"
                    block
                  >
    
                    Enviar comprobante firmado
    
                  </b-button>
    
                </b-col>
    
              </b-row>
            </b-form>
    
          </div>
    
        </b-modal>
          
          </template>
          
          <script>
          import {
            BButton, BRow, VBPopover, BCol, BCard, BForm, BFormFile, BAlert
          } from 'bootstrap-vue'
          import PincodeInput from 'vue-pincode-input'
          import 'firebase/compat/storage'
          import Vue from 'vue'
          
          
          export default {
            components: {
                PincodeInput,
                BForm, BFormFile,
              BButton,
              BRow,
              BCol,
              BCard,
              Vue,
              BAlert
            },
            directives: {
              'b-popover': VBPopover,
            },
            props: ['userId', 'tokenAuth','idTransaction'],
            data() {
          
          
              return {
          
                filePdf: [],
                pinPdf: '',
                isActivePdf: false,
                imageData1: null,
                uploadValue1: 0,
    
              }
            },
            computed: {
          
            },
            watch: {
          
            },
            mounted() {
    
                this.pinPdf = ''
    
                    this.isActivePdf = false
    
                    this.imageData1 = null
    
                    this.uploadValue1 = 0
    
                    this.filePdf = []
    
                    this.$refs.modalComprobante.show()
          
            },
            methods: {
    
                closeModalComprobante(){
                    this.$eventBus.$emit('reiniciarModalComprobante')
                },  
    
                previewImage1(event) {
                const FileSizeBanner = event.target.files[0].size / 1024 / 1024
        
                if (FileSizeBanner > 2) {
                
    
                  this.$toast.error('El archivo PDF debe pesar menos de 2mb', {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                  })

                  this.$refs.modalComprobante.hide()


                }
        },
                
                    updateComprobante(event) {
                    event.preventDefault()
    
                    this.isActivePdf = true
                    this.uploadValue1 = 0
    
                    const d1 = new Date();
    
                    let nameFile= this.userId+"_"+d1.getTime()+"_"+this.filePdf.name;
                    let dir= 'comprobantes/'+this.userId;
    
                      const storageRef = this.$appFirebase.storage().ref(dir).child(`${nameFile}`).put(this.filePdf);
    
                   
                    storageRef.on('state_changed', snapshot => {
                        this.uploadValue1 = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    
                        document.getElementById('btn_pdf').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Subiendo comprobante'
                    }, error => {
                        this.$toast.error("Ocurrió un error inesperado".message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                        })
    
                        this.isActivePdf = false
                        document.getElementById('btn_pdf').innerHTML = 'Enviar comprobante firmado'
                    },
                    () => {
                        this.uploadValue1 = 100
                        storageRef.snapshot.ref.getDownloadURL().then(url => {
                        const data_json = { userId: this.userId, id: this.idTransaction, pin: this.pinPdf }
                        const data_string = JSON.stringify(data_json)
    
                        const dataEncripted = this.$encryptBackoffice.encrypt(data_string)
    
                        document.getElementById('btn_pdf').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Enviando comprobante'
    
                        this.$https.post('/transactions/updateComprobante/', { tokenAuth: this.tokenAuth, data: dataEncripted, url:url }).then(response => {
                            
                           
                            if (response.data.code == 200) {

    
    
                                this.$toast.success(response.data.message, {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                                });

                            this.$refs.modalComprobante.hide();
                            this.$eventBus.$emit('reiniciarListadoTransacciones');

                            } else {
                            this.$toast.error(response.data.message, {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                            })
    
                            if (response.data.code == 401) {
                                this.$refs.modalComprobante.hide()
    
                                
                                localStorage.removeItem('userData')
    

    
                                // Redirect to login page
                                this.$router.push({ name: 'auth-login' })
                            }
                            }
                        }).catch(error => {
                            this.$toast.error("Ocurrió un error inesperado: " + error, {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                            })
                        })
                        })
                    })
                    },
      
            
             
          
            },
          }
          </script>
          
          <style lang="scss">
          
          
          
          </style>
          