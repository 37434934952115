<template>
<div>

  <modal-comprobante v-if="showModalComprobante" :key="componentKeyModalComprobante" :id-transaction="idTransaction" :user-id="userId" :token-auth="tokenAuth" />

  <pdf
  v-if="showPdf"
            :key="componentKeyPdf"
            :user-id="userId"
             :token-auth="tokenAuth"
             :id-transaction="idTransaction"
             :date-formated="dateFormated"
             :display-name-send-money="displayNameSendMoney"
             :display-name-receive-money="displayNameReceiveMoney"
             :source="source"
             :token="token"
             :mount="mount"
             :photo-send-money="photoSendMoney"
             :photo-receive-money="photoReceiveMoney"
          />

<b-row v-if="loading"> 

 
  <b-col  cols="10" sm="10" md="10" xl="10" lg="10">

<h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
      <feather-icon
                icon="ListIcon"
                size="21"
                class="color_icon"
                /> Últimas transacciones
        </h4>

</b-col>


<b-col  cols="2" sm="2" md="2" xl="2" lg="2">
<b-skeleton width="100%"  height="20px"></b-skeleton>

</b-col>



<b-col  cols="4" sm="4" md="4" xl="4" lg="4">


</b-col>

<b-col  cols="4" sm="4" md="4" xl="4" lg="4">

<b-skeleton width="100%"  height="30px"></b-skeleton>

</b-col>

<b-col  cols="4" sm="4" md="4" xl="4" lg="4">


</b-col>

<b-col  cols="3" sm="3" md="3" xl="3" lg="3">


    </b-col>

    <b-col  cols="6" sm="6" md="6" xl="6" lg="6">

    <b-skeleton width="100%"  height="30px"></b-skeleton>

    </b-col>

    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">


    </b-col>

<b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="margin-bottom:10px">




                  <b-skeleton-table
              :rows="2"
              :columns="columnas"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>

</b-col>

<b-col  cols="3" sm="3" md="3" xl="3" lg="3">


</b-col>

<b-col  cols="6" sm="6" md="6" xl="6" lg="6">

<b-skeleton width="100%"  height="30px"></b-skeleton>

</b-col>

<b-col  cols="3" sm="3" md="3" xl="3" lg="3">


</b-col>

<b-col  cols="3" sm="3" md="3" xl="3" lg="3">


</b-col>

<b-col  cols="2" sm="2" md="2" xl="2" lg="2">
<b-skeleton width="100%"  height="30px"></b-skeleton>


</b-col>

<b-col  cols="2" sm="2" md="2" xl="2" lg="2" align="center">
<b-skeleton width="30px" type="avatar"   height="30px"></b-skeleton>


</b-col>

<b-col  cols="2" sm="2" md="2" xl="2" lg="2">
<b-skeleton width="100%"  height="30px"></b-skeleton>


</b-col>


<b-col  cols="3" sm="3" md="3" xl="3" lg="3">


</b-col>





</b-row>

 <b-row v-else-if="code200" style="  overflow: auto;">

  <b-col  cols="10" sm="10" md="10" xl="10" lg="10">

<h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
      <feather-icon
                icon="ListIcon"
                size="21"
                class="color_icon"
                /> Últimas transacciones
        </h4>

</b-col>

<b-col  cols="2" sm="2" md="2" xl="2" lg="2" style="    text-align: right;">


<p style="cursor:pointer;    margin: 0;" @click="refresh()" > 
                                                            <feather-icon
                                                            icon="RefreshCcwIcon"
                                                            size="16"
                                                            style="margin-right:5px"
                                                            /> <span class="d-none d-sm-none d-md-inline-block d-lg-inline-block d-xl-inline-block"> Recargar </span> </p>



</b-col>



     
<b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="padding: 0px;margin-top: 15px;">
        

            <table class="table tablesorter"  id="datatable" >
                              <thead class="thead-light">
                                <tr>
                                  <th>Usuario</th>
                                  <th data-priority="1">ID Transacción</th>
                                  <th data-priority="2">Monto</th>
                                   <th>Tipo</th>
                                
                                  <th >Fecha</th>
                                  
                                   
                                  <th>Estado</th>

                                  <th data-priority="3">Accion</th>
                                 
                                </tr>
                              </thead>
                              <tbody>


                                <tr v-for="transaccion in transactions" :key="transaccion.idTransaction">

                                 <td> 

                                  
                                    <b-avatar
                                      size="35"
                                      :src="transaccion.photo"
                                      variant="light-primary"
                                    ></b-avatar>
                                  <p style="margin-bottom: 0px;font-size: 12px;">{{ transaccion.displayName }}</p>
                                  
                                  </td>

                                  <td>

                                                                      
                                        <b-input-group >

                                        <b-form-input
                                        v-model="transaccion.idTransaction"
                                        
                                        
                                            readonly>
                                            
                                            </b-form-input>

                                        <b-input-group-append>
                                            <b-button
                                            @click="onCopy(transaccion.idTransaction)"
                                            variant="outline-primary"
                                            >

                                            <feather-icon
                                        icon="CopyIcon"
                                        size="15"

                                        /> 
                                            </b-button>
                                        </b-input-group-append>
                                        </b-input-group>


                                        </td>

                                        <td v-if="transaccion.type === 'Receive Money'"> <span>+ ${{ transaccion.mount }}</span></td>
                                        <td v-else><span>- ${{ transaccion.mount }}</span></td>


                                    <td> {{ transaccion.source }}</td>

                                 <td> {{ transaccion.dateFormated }}</td>


                                 <td v-if="transaccion.status === 'Disponible'">
                                  <p style="color:#62ac62; font-weight: bold; margin:0px">
                                        {{transaccion.status}}
                                     </p>

                                    

                                      </td>
                      
                                  <td v-else-if="transaccion.status === 'Pendiente'">

                                    <p style="color:#ff9f43; font-weight: bold; margin:0px">
                                        {{transaccion.status}}
                                     </p>
                                   
                                    
                                    </td>

                                    <td v-else-if="transaccion.status === 'Pendiente de enviar recibo firmado'">

                                      <p style="color:#ff9f43; font-weight: bold; margin:0px">
                                        {{transaccion.status}}
                                     </p>


                                      </td>

                                      <td v-else-if="transaccion.status === 'Enviado para revision'">

                                        <p style="color:#ff9f43; font-weight: bold; margin:0px">
                                        {{transaccion.status}}
                                     </p>


                                      </td>

                                      <td v-else-if="transaccion.status === 'Comprobante rechazado'">

                                        <p style="color:#e20000; font-weight: bold; margin-bottom:10px">
                                            {{transaccion.status}}
                                        </p>

                                        <a class="a_link" style="margin-bottom: 0px;font-size: 12px;margin-top: 10px;" @click="motivoRechazo(transaccion.motivoRechazo)">Ver motivo</a>
                                      


                                        </td>



                                       <td v-else>

                                        <p style="color:#e20000; font-weight: bold; margin:0px">
                                        {{transaccion.status}}
                                     </p>
                                   
                                    
                                    </td>

                                    <td v-if="transaccion.status === 'Pendiente de enviar recibo firmado' || transaccion.status === 'Comprobante rechazado'" > 
                                      <b-dropdown   right  dropdown variant="light" size="sm" block >
                                      <template #button-content>

                                        <b-button 
                                        v-b-tooltip.hover.left="'Más opciones'"
                                                                    
                                                                        variant="outline-secondary"
                                                                        size="sm"
                                                                        block
                                                                        style="margin-top:14px; margin-bottom:10px"
                                                                
                                                                      >
                                                                    
                                                                  
                                                    <feather-icon
                                                      icon="SettingsIcon"
                                                      size="17"
                                                      style="margin-right: 5px;"
                                                    
                                                      />

                                                      <feather-icon
                                                      icon="ChevronDownIcon"
                                                      size="15"
                                                    
                                                      />

                                                                      </b-button>
                                    

                                      </template>

                              


                                        <b-dropdown-item @click="downloadComprobante(transaccion.idTransaction, transaccion.dateFormated,  transaccion.displayNameReceiveMoney, transaccion.displayNameSendMoney,  transaccion.token, transaccion.source, transaccion.mount)">
                                        
                                          <feather-icon
                                          icon="DownloadIcon"
                                          size="20"
                                          style="margin-right: 12px;"
                                          />

                                        Descargar comprobante
                                        </b-dropdown-item>

                                        <b-dropdown-item @click="uploadComprobante(transaccion.idTransaction)">
                                        <feather-icon
                                          icon="UploadIcon"
                                          size="20"
                                          style="margin-right: 12px;"
                                          />Subir comprobante firmado
                                        </b-dropdown-item>

                                      </b-dropdown>
                                    </td>


                                    <td v-else>  <b-button
                                                  variant="outline-secondary"
                                                  v-b-tooltip.hover.left="'Descargar comprobante'"
                                                 @click="downloadComprobante(transaccion.idTransaction, transaccion.dateFormated,  transaccion.displayNameReceiveMoney, transaccion.displayNameSendMoney,  transaccion.token, transaccion.source, transaccion.mount, transaccion.photoReceiveMoney, transaccion.photoSendMoney)"
                                                >

                                                <feather-icon
                                                icon="DownloadIcon"
                                                size="16"
                                               
                                                />

                                                </b-button> </td>
                          

                                </tr>
                              </tbody>
                            </table>

</b-col>




 </b-row>

 <b-row v-else-if="empty">

  <b-col  cols="12" sm="12" md="12" xl="12" lg="12">

<h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
      <feather-icon
                icon="ListIcon"
                size="21"
                class="color_icon"
                /> Últimas transacciones
        </h4>

</b-col>

 <b-col  align="center" cols="12" sm="12" md="12" xl="12" lg="12">
  <svg width="100" height="100" class="icons_colors" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g data-name="17. Withdraw" id="_17._Withdraw"><path d="M31 0H1A1 1 0 0 0 0 1V13a1 1 0 0 0 1 1H6V31a1 1 0 0 0 1 1H25a1 1 0 0 0 1-1V14h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zM2 2H30V4H2zM24 30H8V10H24zm6-18H26V10h1a1 1 0 0 0 0-2H5a1 1 0 0 0 0 2H6v2H2V6H30z"/><path d="M29 23a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V24a1 1 0 0 0-1-1z"/><path d="M3 16a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V17a1 1 0 0 0-1-1z"/><path d="M21 16h-.18A3 3 0 0 0 15 17a1 1 0 0 1-2 0V15a1 1 0 0 0-2 0v1a1 1 0 0 0 0 2h.18A3 3 0 0 0 17 17a1 1 0 0 1 2 0v2a1 1 0 0 0 2 0V18a1 1 0 0 0 0-2z"/><path d="M16 23a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V24a1 1 0 0 0-1-1z"/></g></svg>                    
                                               

                         <p class="errorTexto">
                        No se encontraron transacciones
                      </p>

              </b-col>

 <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="    text-align: center;">

                           <b-button   variant="primary"  @click="refresh()" > 
                                         <feather-icon
                                          icon="RefreshCcwIcon"
                                          size="16"
                                         
                                          style="margin-right:5px; color:#fff"
                                          /> Recargar transacciones </b-button>


 </b-col>
</b-row>


<b-row v-else>

 <b-col  cols="12" sm="12" md="12" xl="12" lg="12" align="center">


             <feather-icon
             icon="FrownIcon"
             size="50"
             />
           <p class="errorTexto">
                Ocurrió un error al cargar los datos
              </p>

              <b-button
                id="btn_refresh"
                ref="btn_refresh"
                variant="primary"
                @click="refresh"
              >

                Intentar de nuevo

              </b-button>
                
        

              </b-col>

            
</b-row>
</div>
</template>

<script>
import {
  BButton, BRow, BCol,BSkeleton,BDropdown,VBTooltip,VBPopover, BDropdownItem, BCard,BSkeletonTable, BAvatar,BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup, 
} from 'bootstrap-vue'
import "jquery/dist/jquery.min.js";
import ModalComprobante from './ModalComprobante.vue'
import $ from "jquery";
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css'
import 'datatables.net-responsive-dt/js/responsive.dataTables.min.js'
import Pdf from './Pdf.vue'
export default {
  components: {
    BButton,
    ModalComprobante,
    BDropdown, BDropdownItem,
    BSkeleton,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormGroup,
    BSkeletonTable,
    BRow,
    BCol,
    BCard,
    BAvatar,
    Pdf
  },
  directives: {
          'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  props: ['userId','tokenAuth', 'type', 'status'],
  data() {


    const mq = window.matchMedia( "(min-width: 990px)" );
    let columnas=6;

    if (!mq.matches) {

      columnas =3;
      }

    

    return {
      code200: false,
      loading: true,
      showModalComprobante:false,
       empty:false,
       isDisabled:false,
       transactions:[],
       columnas:columnas,
       idTransaction:"",
       componentKeyPdf:0,
       showPdf:false,
       dateFormated:"",
       displayNameReceiveMoney:"",
       displayNameSendMoney:"",
       mount:"",
       photoSendMoney:"",
       photoReceiveMoney:"",
       componentKeyModalComprobante:100


    }


  },
  computed: {

  },
  watch: {

  },
  mounted() {

    this.$eventBus.$on('reiniciarModalComprobante', () => {
      this.componentKeyModalComprobante += 1
      this.showModalComprobante=false;
    })

    
    this.$eventBus.$on('reiniciarPdf', () => {
      this.componentKeyPdf += 1
      this.showPdf=false;
    });




         const userId_json = { userId: this.userId, type: this.type, status: this.status }
 

      this.$https.post('/transactions/transactionsByFilter/', { tokenAuth: this.tokenAuth, data: userId_json }).then(response => {
         this.loading=false;

  

        
 
                      if(response.data["code"] == 200){
                      
                     
                
                        this.$eventBus.$emit('isDisabledFalseTransacciones')

        

                        this.transactions= response.data["transactions"];
                       
 
                 
                                  setTimeout(() => {
                                    $("#datatable").DataTable({
                                      responsive: true,
                                      "ordering": false,
                                      lengthMenu: [
                                        [5,10, 25, 50, -1],
                                        [5,10, 25, 50, "All"],
                                      ],
                                      pageLength: 5, 
                                         "language": {
                                              "search": "Buscar:",
                                              "sInfo":      "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
	                                            "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                              "searchPlaceholder": "Ingrese algún dato",
                                              "zeroRecords": "Lo sentimos, no se encontraron resultados",
                                              "processing": "Buscando. Espere por favor...",
                                              "sLengthMenu":     "Mostrar _MENU_ registros",
                                              "oPaginate": {
                                                      "sFirst":    "Primero",
                                                      "sLast":     "Último",
                                                      "sNext":     "Siguiente",
                                                      "sPrevious": "Anterior"
                                                  },

                                              }
                                    });
                                  });

                                     this.code200=true;

                                    

                                 
  
                          
                        }else{

                        

                            


                                  if (response.data.code == 401) {

                                    this.$toast.error(response.data.message, {
                                      position: 'top-right',
                                      timeout: 3010,
                                      closeOnClick: true,
                                      pauseOnFocusLoss: false,
                                      pauseOnHover: true,
                                      draggable: true,
                                      draggablePercent: 0.4,
                                      showCloseButtonOnHover: false,
                                      hideProgressBar: true,
                                      closeButton: 'button',
                                      icon: true,
                                      rtl: false,
                                    })

                                    
                                    localStorage.removeItem('userData')



                                    
                                    

           

                                    
                                    

                                    // Redirect to login page
                                    this.$router.push({ name: 'auth-login' })
                                  } else {

                                     if (response.data.code == 404) {



                                      this.empty=true;

                                    }else{

                                         this.$toast.error(response.data.message, {
                                          position: 'top-right',
                                          timeout: 3010,
                                          closeOnClick: true,
                                          pauseOnFocusLoss: false,
                                          pauseOnHover: true,
                                          draggable: true,
                                          draggablePercent: 0.4,
                                          showCloseButtonOnHover: false,
                                          hideProgressBar: true,
                                          closeButton: 'button',
                                          icon: true,
                                          rtl: false,
                                        })

                                        this.code200 = false

                                    }
                                  


                                  }

                        }
                 }).catch((error) => {

                           this.code200=false;

                      this.$toast.error("Ocurrió un error inesperado", {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })

                     
                 });



  },
  methods: {

    motivoRechazo(motivo){

      this.$toast.error(motivo, {
        position: 'top-right',
        timeout: 1500,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })

    },


    downloadComprobante(id, date, displayNameReceiveMoney, displayNameSendMoney, token, source, mount, photoReceiveMoney, photoSendMoney){

      this.idTransaction=id;
      this.showPdf=true;
      this.dateFormated=date;
      this.displayNameReceiveMoney=displayNameReceiveMoney;
      this.displayNameSendMoney=displayNameSendMoney;
      this.mount=mount;
      this.photoReceiveMoney=photoReceiveMoney;
      this.photoSendMoney= photoSendMoney;
      this.token=token;
      this.source=source;
    },
    uploadComprobante(id){
      this.showModalComprobante=true;
      this.idTransaction=id;
    },

    refresh() {
      this.$eventBus.$emit('reiniciarListadoTransacciones')
    },
    onCopy(copy) {
      navigator.clipboard.writeText(copy);

      this.$toast.success('Texto copiado!', {
        position: 'top-right',
        timeout: 1500,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    },
    onError() {
      this.$toast.error('Falló en copiar el texto', {
        position: 'top-right',
        timeout: 1500,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    },


  },
}
</script>

<style lang="scss">


</style>